// extracted by mini-css-extract-plugin
export var boldText = "style-module--bold-text--db3e6";
export var cardImage = "style-module--card-image--f9395";
export var cardImageImg = "style-module--card-image-img--8a8d6";
export var description = "style-module--description--f0f4d";
export var getStarted = "style-module--get-started--f16ef";
export var getStartedButton = "style-module--get-started-button--66cfd";
export var heading = "style-module--heading--e557e";
export var listHeader = "style-module--list-header--5c3d6";
export var productRow = "style-module--product-row--e2f88";
export var productValuesHeader = "style-module--product-values-header--1a241";
export var seeDetailsBelow = "style-module--see-details-below--18961";
export var subHeading = "style-module--sub-heading--80e4b";