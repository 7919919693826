import Provider from "models/Provider"
import React from "react"
import ProductComponent from "components/content/personal-loans/product-component"
import PersonalLoanProduct from "models/product-models/PersonalLoanProduct"
import { useMediaQuery } from "@mui/material"
import Product from "models/Product"
import * as styles from "./style.module.scss"
import { Typography } from "components/typography"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import AppManager from "services/app-manager"
import PersonalLoanProvider from "models/provider-models/PersonalLoanProvider"
import { JsonLd } from "react-schemaorg"
import { BreadcrumbList } from "schema-dts"
import Layout from "components/layout"
import ReturnLinkChain from "components/content/return-link-chain"
// import { CardAds } from "components/content/card-ads"
import { ListHeaderProduct } from "components/content/list-header"
import { PersonalLoan as ProductDetails } from "components/content/product-details"
import { PersonalLoanValuesHeader } from "components/content/product-values-header"
import { PersonalLoanDisclaimer } from "components/content/content-disclaimer"
import LoanFilterBar from "components/content/personal-loans/input-filter"
import FactCheckedDialog from "components/content/fact-checked-dialog"

const BoldText = ({ children }: { children: any }) => {
  return <span className={styles.boldText}>{children}</span>
}

function makeProductComponent(product: Product, key: number, isLast?: boolean) {
  return (
    <div key={key} style={{ borderBottom: isLast ? "" : "0.5pt #D2D2D2 solid" }}>
      <ProductComponent product={product as PersonalLoanProduct} learnMore />
    </div>
  )
}

function makeReviewComponent(reviews: string[], p3Array: { [id: string]: any }[]): JSX.Element {
  if (!reviews[0]) {
    return <div />
  }
  return (
    <div>
      <ListHeaderProduct>Expert Review</ListHeaderProduct>
      {reviews.map((review, key) => (
        <Typography.P style={{ marginBottom: "15px" }}>
          {review}{" "}
          {key > 1
            ? p3Array.map(p3 => (
                <div>
                  <div style={{ fontWeight: "bold", color: "#E7527A" }}>{p3.offerTitle}</div>
                  {p3.offerDescription}
                  {p3.offerDescription !== "" ? (
                    <span
                      style={{ textDecoration: "none", color: "#4f8ccc", cursor: "pointer" }}
                      onClick={() => {
                        DataCollector.getInstance().addAction(DataCollectorAction.CONTENT_GET_OFFER_SELECTED, { offerId: p3.offer.offerId, providerId: p3.offer.providerId, expertReview: true })
                        AppManager.getInstance().getOffer("Go", p3.offer.offerId, p3.offer, p3.offer.providerId, p3.providerName)
                      }}
                    >
                      here.
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              ))
            : ""}
        </Typography.P>
      ))}
    </div>
  )
}

const ProductTemplate = (props: any) => {
  const { providerJson, productId, dateUpdated, ads }: { providerJson: Provider; productId: string; dateUpdated: string; ads: any } = JSON.parse(props.pageContext.p)
  const isTablet = !useMediaQuery("(min-width:1024px)")
  const isWide = useMediaQuery("(min-width:768px)")

  const provider: PersonalLoanProvider = PersonalLoanProvider.Initialise(providerJson)
  const product: PersonalLoanProduct = PersonalLoanProduct.Initialise(provider.products[productId] as PersonalLoanProduct)
  const nameHeading: string = product.nameLong
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const dateString = dateUpdated.substring(8, 10) + " " + months[parseInt(dateUpdated.substring(5, 7)) - 1] + " " + dateUpdated.substring(0, 4)

  const defaultLoanSize = product.minLoanAmount > 30000 ? product.minLoanAmount : product.maxLoanAmount && product.maxLoanAmount < 30000 ? product.maxLoanAmount : 30000
  const availableLoanTerms = product.getAvailableLoanTerms()
  const defaultLoanTerm = availableLoanTerms.includes(5) ? 5 : availableLoanTerms.length > 0 ? availableLoanTerms[Math.floor((availableLoanTerms.length - 1) / 2)] : 0

  const [loanSize, setLoanSize] = React.useState(defaultLoanSize)
  const [loanTerm, setLoanTerm] = React.useState(defaultLoanTerm)

  const alternativeProducts: JSX.Element[] = provider.getAlternativeProductList(product, makeProductComponent)

  function makeSchemaOrg(product: Product, provider: Provider, nameHeading: string): JSX.Element {
    return (
      <div>
        <JsonLd<BreadcrumbList>
          item={{
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Credit Cards",
                item: "https://www.stayorgo.com.au/personal-loans",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: product.providerName,
                item: "https://www.stayorgo.com.au/" + provider.nameFormatted + "-personal-loans",
              },
              {
                "@type": "ListItem",
                position: 3,
                name: nameHeading,
                item: "https://www.stayorgo.com.au/" + product.urlSlug,
              },
            ],
          }}
        />
        {/* <JsonLd<CreditCard>
          item={{
            "@context": "https://schema.org",
            "@type": "CreditCard",
            name: seoName,
            description: description,
          }}
        /> */}
      </div>
    )
  }

  const useLoanSize = loanSize >= product.minLoanAmount ? loanSize : loanSize === 0 ? defaultLoanSize : 0

  const ExpertReviewSection = () => {
    return <div>{product.getExpertReview(provider, makeReviewComponent)}</div>
  }

  let breadcrumbName = product.name.replace(provider.name, "") || product.name

  return (
    <Layout title={`${nameHeading} - Review | Stay or Go`} description={product.autoDescription} urlSlug={product.urlSlug ? product.urlSlug : undefined} dateUpdated={dateUpdated}>
      <div className="page-container">
        <div className="content-provider-container">
          {product.carLoan === "Y" ? (
            <ReturnLinkChain
              linkChain={[
                { name: "Car Loans", to: "/car-loans/" },
                { name: provider.name, to: "/" + provider.urlSlug + "-car-loans" },
              ]}
              currentLocationItem={{ name: breadcrumbName }}
              excludeBottomMargin={isWide}
              includeFactCheckedDialog={isWide}
              factCheckedDialogDateUpdated={dateString}
            />
          ) : (
            <ReturnLinkChain
              linkChain={[
                { name: "Personal Loans", to: "/personal-loans/" },
                { name: provider.name, to: "/" + provider.urlSlug + "-personal-loans" },
              ]}
              currentLocationItem={{ name: breadcrumbName }}
              excludeBottomMargin={isWide}
              includeFactCheckedDialog={isWide}
              factCheckedDialogDateUpdated={dateString}
            />
          )}
          <div className={styles.heading}>
            {makeSchemaOrg(product, provider, nameHeading)}
            <Typography.H1>{nameHeading}</Typography.H1>
          </div>
          {product.forSale === "GRANDFATHERED" ? <div className={styles.subHeading}>(no longer for sale)</div> : null}
          {product.forSale === "PAUSED" ? <div className={styles.subHeading}>(currently not for sale)</div> : null}
          <div className={styles.description}>
            <Typography.P comp={"content-p"} fontColor={"#A6A6A6"}>
              {product.autoDescription}
            </Typography.P>
            <br />
            <Typography.P comp={"content-p"} fontColor={"#A6A6A6"}>
              Updated {dateString}
            </Typography.P>
            {!isWide && (
              <>
                <br />
                <FactCheckedDialog dateString={dateString} />
              </>
            )}
          </div>
          <div style={{ display: "flex", justifyContent: isWide ? "flex-end" : "center" }}>
            <LoanFilterBar loanSize={loanSize} defaultLoanSize={defaultLoanSize} minLoanSize={product.minLoanAmount} maxLoanSize={product.maxLoanAmount ? product.maxLoanAmount : undefined} loanTerm={loanTerm} validLoanTerms={product.getAvailableLoanTerms()} setLoanSize={setLoanSize} setLoanTerm={setLoanTerm} mobile={!isWide} />
          </div>
          {!isWide && <div style={{ height: "40px" }} />}
          {/* TODO: add ads */}
          {/* {ads ? <CardAds ads={ads} /> : null} */}
          <div className="show-for-desktop">
            <ProductComponent loanSize={useLoanSize} termLength={loanTerm} product={product} hideName />
          </div>
          <div className="show-for-mobile">
            <ProductComponent loanSize={useLoanSize} termLength={loanTerm} product={product} mobileProduct />
          </div>
          {/* TODO: Get Started Button (refer to the product page for Credit Cards for details) */}
          {/* TODO: define when to show the below disclaimer signifier (replace the 'true' on the next line) */}
          {true ? (
            <Typography.P className={styles.seeDetailsBelow} comp={"flat-18"}>
              * See details below
            </Typography.P>
          ) : null}
          <ExpertReviewSection />
          <ListHeaderProduct>Product Details</ListHeaderProduct>
          {product.description !== null ? (
            <div style={{ marginBottom: "30px", marginTop: "30px" }}>
              <div style={{ color: "#797979", fontFamily: "Arboria-Book, sans-serif", fontSize: "16px", display: "flex" }}>
                <img alt="" src="/images/icon_quote.png" style={{ width: "20px", height: "15.66px", marginRight: "15px" }} />
                <div>{product.description}"</div>
              </div>
              <div style={{ color: "#E7527A", fontFamily: "Arboria-Bold, sans-serif", textAlign: "right", fontSize: "16px" }}>- {provider.name}</div>
            </div>
          ) : null}
          <ProductDetails product={product} provider={provider} />
          {alternativeProducts.length > 0 ? (
            <>
              <div className={styles.productValuesHeader} style={{ marginTop: "60px", marginBottom: "0px", backgroundColor: "#FBFCFC", paddingTop: "11px", paddingBottom: "27px", height: "60px" }}>
                <PersonalLoanValuesHeader />
              </div>
              <ListHeaderProduct style={{ marginTop: "0px" }}>Alternative Personal Loans</ListHeaderProduct>
            </>
          ) : null}
          {alternativeProducts}
          <PersonalLoanDisclaimer isDisclaimerOpen />
        </div>
      </div>
    </Layout>
  )
}

export default ProductTemplate
